import { useEffect, useState } from 'react'
import { useLayoutStore } from '@/store/layout'
import { GetcustomerCodeEnum } from '@/constants/user'

export const useCustomerServiceLink = () => {
  const [customerServiceLinks, setCustomerServiceLinks] = useState([])
  const { footerData } = useLayoutStore()
  const { columnsDatas } = footerData || {}
  const allMenus = [...(columnsDatas || [])]

  useEffect(() => {
    function getCustomerServiceLink(data) {
      for (const item of data) {
        if (item.id === GetcustomerCodeEnum.technicalSupport) {
          const webUrl = item.childColumns.find(child => child.id === GetcustomerCodeEnum.customerService)?.webUrl
          return webUrl || ''
        }
      }
      return ''
    }

    const plainArray = allMenus.map(item => JSON.parse(JSON.stringify(item)))
    const link = getCustomerServiceLink(plainArray)
    setCustomerServiceLinks(link)
  }, [footerData])

  return customerServiceLinks
}
